.bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.from-zinc-900 {
    --tw-gradient-from: #18181b var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.hipHop_slide-image {
    width: 100% !important;
    max-width: 250px !important;
}

@media only screen and (max-width: 399px) {
    .hipHop_slide-image {
        max-width: 100% !important;
    }
}

.react-pdf__Page__canvas {
    height: 10px !important;
}
.react-pdf__Page__annotations{
    height: 10px !important;
}

.cabinet-title {
    font-size: 3rem;
    text-align-last: center;
}